<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label='접종월'
            type="month"
            :range="true"
            name="reserveTime"
            v-model="searchParam.reserveTime"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="LBLDEPT"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="예방접종 접종자 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
          selection="multiple"
          rowKey="col2"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addrow" />
              <c-btn label="LBLREMOVE" icon="remove" />
              <c-btn label="LBLSAVE" icon="save" />
              <c-btn label="조회" icon="search" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'gov-schedule-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '60%',
        top: '10',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        reserveTime: '',
        deptCd: '',
      },
      title: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'GOV_SCHEDULE_HISTORY',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: 'LBLNAME',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '성별',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '접종일',
            align: 'center',
            type: 'date',
            setHeader: true,
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '다음예정 접종일',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        govScheduleHistoryId: '',  // 대관업무 이력 일련번호
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        execDt: '',  // 실시일자
        execUserId: '',  // 대관업무 실시자
        remarks: '',  // 내용
      },
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.env.gov.history.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.env.gov.history.list.url;
      this.detailUrl = selectConfig.env.gov.history.get.url;
      this.insertUrl = transactionConfig.env.gov.history.insert.url;
      this.updateUrl = transactionConfig.env.gov.history.update.url;
      this.deleteUrl = transactionConfig.env.gov.history.delete.url;
      this.grid.data = [
        {
          col1: '설비팀',
          col2: '이성민',
          col3: '남',
          col4: '2022-10-12',
          col5: '2023-10-12',
        },
        {
          col1: '설비팀',
          col2: '김성민',
          col3: '남',
          col4: '2022-10-12',
          col5: '2023-10-12',
        },
        {
          col1: '설비팀',
          col2: '박성민',
          col3: '남',
          col4: '2022-10-12',
          col5: '2023-10-12',
        },
        {
          col1: '설비팀',
          col2: '최성민',
          col3: '남',
          col4: '2022-10-12',
          col5: '2023-10-12',
        },
        {
          col1: '설비팀',
          col2: '임성민',
          col3: '남',
          col4: '2022-10-12',
          col5: '2023-10-12',
        },
      ]
      // this.getDetail();
      // this.getList();
    },
    getDetail() {
      if (this.param.govScheduleMstId) {
        this.$http.url = selectConfig.env.gov.mst.get.url;
        this.$http.param = {govScheduleMstId: this.param.govScheduleMstId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.title = '대관업무 이력 ('+_result.data.govScheduleTypeName+' > '+_result.data.govScheduleName+')'
        },);
      }
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getEnvList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {govScheduleMstId: this.param.govScheduleMstId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.reset();
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.dataeditable = true;
      this.$http.url = this.detailUrl;
      this.$http.param = {govScheduleHistoryId: row.govScheduleHistoryId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.$set(this.attachInfo, 'taskKey', row.govScheduleHistoryId)
      },
      () => {
      });
    },
    addEnv() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        govScheduleHistoryId: '',  // 대관업무 이력 일련번호
        govScheduleMstId: this.param.govScheduleMstId,  // 대관업무 마스터 일련번호
        execDt: '',  // 실시일자
        execUserId: this.$store.getters.user.userId,  // 대관업무 실시자
        remarks: '',  // 내용
      };
      this.rowRemoveSelect();
      this.$set(this.attachInfo, 'taskKey', '')
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        govScheduleHistoryId: '',  // 대관업무 이력 일련번호
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        execDt: '',  // 실시일자
        execUserId: '',  // 대관업무 실시자
        remarks: '',  // 내용
      };
    },
    saveEnv() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteEnv() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.govScheduleHistoryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ govScheduleHistoryId: result.data.govScheduleHistoryId })
      } else {
        this.$set(this.attachInfo, 'taskKey', result.data.govScheduleHistoryId)
        this.$set(this.attachInfo, 'isSubmit', uid())
        this.getEnvList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getEnvList();
    },
    addrow() {
      this.popupOptions.title = '접종대상자 추가'; // 접종대상자 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>